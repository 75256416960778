@mixin mq($min: false, $max: false, $height: false, $and: false, $media: all) {
  $_direction: if($height, height, width);

  $mq: '#{$media} ';

  @if $min {
    $mq: '#{$mq} and (min-#{$_direction}: #{$min})';
  }

  @if $max {
    $mq: '#{$mq} and (max-#{$_direction}: #{$max})';
  }

  @if $and {
    $mq: '#{$mq} and #{$and}';
  }

  @media #{$mq} {
    @content;
  }
}
