@import '_palette.scss';

:root {
  --colour-light: var(--tone-100);
  --colour-dark: var(--tone-900);
  --colour-active-alternate-light: var(--colour-alternate-100);
  --colour-active-alternate: var(--colour-alternate-500);
  --colour-active-alternate-dark: var(--colour-alternate-900);

  --font-heading: 'Sweater School', -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Symbol;

  --px-base-rem: 16px;
  --px-base-rem-mobile: 14px;
  --px-spacing-base-unit: 16px;

  --notification-text: var(--colour-alt-text);
  --notification-text-body: var(--colour-alt-text-secondary);
}
