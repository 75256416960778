@font-face {
  font-family: 'SweaterSchoolSb-Regular';
  src: url('https://cdn.nessy.com/static/readingspelling/font/webfonts/2D6157_0_0.eot');
  src: url('https://cdn.nessy.com/static/readingspelling/font/webfonts/2D6157_0_0.eot?#iefix')
      format('embedded-opentype'),
    url('https://cdn.nessy.com/static/readingspelling/font/webfonts/2D6157_0_0.woff2')
      format('woff2'),
    url('https://cdn.nessy.com/static/readingspelling/font/webfonts/2D6157_0_0.woff')
      format('woff'),
    url('https://cdn.nessy.com/static/readingspelling/font/webfonts/2D6157_0_0.ttf')
      format('truetype');
}

@font-face {
  font-family: 'SweaterSchoolRg-Bold';
  src: url('https://cdn.nessy.com/static/readingspelling/font/webfonts/2D6157_1_0.eot');
  src: url('https://cdn.nessy.com/static/readingspelling/font/webfonts/2D6157_1_0.eot?#iefix')
      format('embedded-opentype'),
    url('https://cdn.nessy.com/static/readingspelling/font/webfonts/2D6157_1_0.woff2')
      format('woff2'),
    url('https://cdn.nessy.com/static/readingspelling/font/webfonts/2D6157_1_0.woff')
      format('woff'),
    url('https://cdn.nessy.com/static/readingspelling/font/webfonts/2D6157_1_0.ttf')
      format('truetype');
}

@font-face {
  font-family: 'SweaterSchoolRg-Regular';
  src: url('https://cdn.nessy.com/static/readingspelling/font/webfonts/2D6157_2_0.eot');
  src: url('https://cdn.nessy.com/static/readingspelling/font/webfonts/2D6157_2_0.eot?#iefix')
      format('embedded-opentype'),
    url('https://cdn.nessy.com/static/readingspelling/font/webfonts/2D6157_2_0.woff2')
      format('woff2'),
    url('https://cdn.nessy.com/static/readingspelling/font/webfonts/2D6157_2_0.woff')
      format('woff'),
    url('https://cdn.nessy.com/static/readingspelling/font/webfonts/2D6157_2_0.ttf')
      format('truetype');
}

@font-face {
  font-family: 'SweaterSchoolXb-Regular';
  src: url('https://cdn.nessy.com/static/readingspelling/font/webfonts/2D6157_3_0.eot');
  src: url('https://cdn.nessy.com/static/readingspelling/font/webfonts/2D6157_3_0.eot?#iefix')
      format('embedded-opentype'),
    url('https://cdn.nessy.com/static/readingspelling/font/webfonts/2D6157_3_0.woff2')
      format('woff2'),
    url('https://cdn.nessy.com/static/readingspelling/font/webfonts/2D6157_3_0.woff')
      format('woff'),
    url('https://cdn.nessy.com/static/readingspelling/font/webfonts/2D6157_3_0.ttf')
      format('truetype');
}
