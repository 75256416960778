@import '../utils/utils.scss';

.signpost-body {
  background: url(/assets/background-mobile.png) no-repeat center center fixed;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;

  @include mq($breakpoint-tablet) {
    background: url(/assets/background-tablet.png) no-repeat center center fixed;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
  }

  @include mq($breakpoint-desktop) {
    height: 100vh;
    background: url(/assets/background.png) no-repeat center center fixed;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {
  color: var(--colour-heading);
  margin-bottom: spacing(1);
  line-height: 1.2;
}

h1,
.h1 {
  font-size: 1.5rem;
  @include mq($breakpoint-desktop) {
    font-size: 2.5rem;
  }
}

h2,
.h2 {
  font-size: 1.3rem;
  @include mq($breakpoint-desktop) {
    font-size: 2rem;
  }
}

h3,
.h3 {
  font-size: 1.1rem;
  @include mq($breakpoint-desktop) {
    font-size: 1.5rem;
  }
}

h4,
.h4 {
  font-size: 1rem;
}

h5,
.h5 {
  font-size: 0.75rem;
}

a {
  cursor: pointer;
  text-decoration: none;
}

button,
.button {
  cursor: pointer;
  border: 2px solid var(--colour-dark);
  display: inline-block;
  padding: spacing(0.75) spacing(1.25);
  font-size: 1.5rem;
  border-radius: spacing(0.25);
  font-weight: 500;
  width: 100%;
  background-color: var(--colour-active);
  color: var(--colour-light);
  &:hover,
  &:active,
  &:focus {
    background-color: var(--colour-active-dark);
  }
  &:disabled {
    background-color: var(--colour-inactive-light);
  }

  @include mq($breakpoint-desktop) {
    width: fit-content;
  }

  &.btn-alternate {
    background-color: var(--colour-active-alternate);
    color: var(--colour-light);

    &:hover,
    &:active,
    &:focus {
      color: var(--colour-light);
      background-color: var(--colour-active-alternate-dark);
    }
  }
}

// custom scrollbar
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
}
 
::-webkit-scrollbar-thumb {
  background: #3bae70;
}

::-webkit-scrollbar-thumb:hover {
  background: #2b8052;
  cursor: pointer;
}