@import './styles/base/base.scss';
@import './styles/theme/theme.scss';
@import './styles/utils/utils.scss';

.signpost-mobile {
  height: 16rem;
  background: url(/assets/board-mobile.svg) center center no-repeat;
  background-size: 300px 140px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 4rem;
  z-index: 2;
}

a.disabled {
  pointer-events: none;
  cursor: not-allowed;
}

img.app-unlocked {
  animation: glow 2.5s infinite;
  animation-timing-function: ease-in-out;
  @keyframes glow {
    0% {
      filter: drop-shadow(0px 0px 2px rgb(239, 227, 171, 0.1));
      transform: scale(1);
    }

    50% {
      filter: drop-shadow(0px 0px 15px rgb(255, 238, 155));
      transform: scale(1.1);
    }
  }
}
