:root {
  --primary-900: #236843;
  --primary-800: #297a4e;
  --primary-700: #2f8b5a;
  --primary-600: #359d65;
  --primary-500: #3bae70;
  --primary-400: #4fb67e;
  --primary-300: #62be8d;
  --primary-200: #76c69b;
  --primary-100: #89cea9;

  --secondary-900: #443161;
  --secondary-800: #503971;
  --secondary-700: #5b4181;
  --secondary-600: #674991;
  --secondary-500: #7251a1;
  --secondary-400: #020202;
  --secondary-300: #8e74b4;
  --secondary-200: #9c85bd;
  --secondary-100: #aa97c7;

  --colour-background-primary-900: #7c9192;
  --colour-background-primary-800: #90a9aa;
  --colour-background-primary-700: #a5c2c2;
  --colour-background-primary-600: #b9dadb;
  --colour-background-primary-500: #cef2f3;
  --colour-background-primary-400: #d3f3f4;
  --colour-background-primary-300: #d8f5f5;
  --colour-background-primary-200: #ddf6f7;
  --colour-background-primary-100: #e2f7f8;

  --colour-background-secondary-900: #374f50;
  --colour-background-secondary-800: #405c5d;
  --colour-background-secondary-700: #4a6a6a;
  --colour-background-secondary-600: #537778;
  --colour-background-secondary-500: #5c8485;
  --colour-background-secondary-400: #6c9091;
  --colour-background-secondary-300: #7d9d9d;
  --colour-background-secondary-200: #8da9aa;
  --colour-background-secondary-100: #9db5b6;

  --tone-900: #323232;
  --tone-800: #313131;
  --tone-700: #484848;
  --tone-600: #5f5f5f;
  --tone-500: #767676;
  --tone-400: #8d8d8d;
  --tone-300: #a3a3a3;
  --tone-200: #e5e5e5;
  --tone-150: #f2f2f2;
  --tone-100: #ffffff;

  --colour-alt-text: #0f9112;
  --colour-alt-text-secondary: #7f7b33;

  --nessy-red: #db2c2a;
  --nessy-amber: #f5d500;
  --nessy-purple: #7150a5;
  --nessy-blue: #00bdff;
  --dark-red: #b3261e;
  --nessy-orange: #ed7016;

  --colour-alternate-500: #155888;
  --colour-alternate-100: #739bb8;
  --colour-alternate-200: #5b8aac;
  --colour-alternate-300: #4479a0;
  --colour-alternate-400: #2c6994;
  --colour-alternate-500: #155888;
  --colour-alternate-600: #134f7a;
  --colour-alternate-700: #11466d;
  --colour-alternate-800: #0f3e5f;
  --colour-alternate-900: #0d3552;
}
